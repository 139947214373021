import React from "react";
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../../../components/layout";
import Seo from "../../../../components/seo";
import {graphql} from 'gatsby';
import Tab from '../../../../components/support/electronic-locks/t-series/Tab';

const IndexPage = () => {
  const {t} = useTranslation();

  return (
      <Layout>
        <Seo title={t('support_electronic_locks_t_series')} description={t('des_support_electronic_locks_t_series')}/>
        <div className="support">
          <section className="hero">
            <div className="container">
              <h1>T-series<sup>&trade;</sup></h1>
              <br/>
              <Tab />
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
