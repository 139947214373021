import React, {useState} from "react";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../../../components/layout";
import Seo from "../../../../components/seo";
import {StaticImage} from "gatsby-plugin-image";
import {graphql} from 'gatsby';
import BookConsultation from "../../../../components/pages/BookConsultation";
import ReactVideo from "../../../../components/video-player/ReactVideo";
import posterDigilink from "../../../../images/smart-locks/digilink-poster.png";
import circleCheck from "../../../../images/shared/circle-check-large.svg";

const IndexPage = () => {

  const {t} = useTranslation();
  const [whichRollover, setWhichRollover] = useState('none');

  const handleWhichRollover = (which) => {
    let whichRoll = which.target.dataset.roll;
    setWhichRollover(whichRoll);
  }
  const handleWhichRollOut = () => {
    setWhichRollover('none');
  }

  return (
      <Layout>
        <Seo
            title={t('products_digilink')}
            description={t('des_products_digilink')}
        />
        <div className="products digilink-page">
          <section className={'hero-pages'}>
            <div className="container">
              <div className="grid-two-col">
                <div className="grid-two-col-item-single">
                  <div>
                    <h1 dangerouslySetInnerHTML={{__html:t('digilink_headline')}} />
                    <div className="grid-two-col-item-single tablet-mobile">
                      <div>
                        <StaticImage
                            src="../../../../images/smart-locks/hero-digilink-tablet.png"
                            loading={'lazy'}
                            width={894}
                            height={467}
                            quality={100}
                            formats={["auto", "webp", "avif"]}
                            alt="Pivot locker"
                            className={'curve-family'}
                        />
                      </div>
                    </div>
                    <p dangerouslySetInnerHTML={{__html: t('digilink_headline_copy')}} />
                    <BookConsultation/>
                  </div>
                </div>
                <div className="grid-two-col-item-single tablet-desktop">
                    <StaticImage
                        src="../../../../images/smart-locks/hero-digilink-tablet.png"
                        loading={'lazy'}
                        width={894}
                        height={467}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Pivot locker"
                        className={'curve-family'}
                    />
                </div>
              </div>
            </div>
          </section>
          <section class="one-system">
            <div class="container">
              <h2 dangerouslySetInnerHTML={{__html:t('one_system_headline')}} />
              <div className="video-holder">
                <ReactVideo
                    vURL={'https://cdn.mediavalet.com/usva/digilock/JzTSZmYf4kK5UVJLZzCi9w/rYz-WTrTgUyaUDk1YC511A/Widescreen720p/Digilock%20Digilink%20App%20Highlight%20V6.mp4'}
                    poster={posterDigilink} />
              </div>
              <p dangerouslySetInnerHTML={{__html:t('one_system_copy')}} />
            </div>
          </section>
          <section class="smart-lock-technology">
            <div class="container">
              <StaticImage
                  src="../../../../images/smart-locks/logo-digilink-white.png"
                  loading={'lazy'}
                  width={284}
                  height={90}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Digilink Logo"
              />
              <h2 dangerouslySetInnerHTML={{__html:t('smart_headline')}} />
              <div class="checks">
                <div class="checkbox">
                  <img src={circleCheck} width={'39'} height={'39'} alt="check"/>
                  <p dangerouslySetInnerHTML={{__html:t('smart_check_one')}} />
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="2" height="148" viewBox="0 0 2 148" fill="none">
                  <path d="M1 0V148" stroke="white"/>
                </svg>
                <div class="checkbox">
                  <img src={circleCheck} width={'39'} height={'39'} alt="check"/>
                  <p dangerouslySetInnerHTML={{__html:t('smart_check_two')}} />
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="2" height="148" viewBox="0 0 2 148" fill="none">
                  <path d="M1 0V148" stroke="white"/>
                </svg>
                <div class="checkbox">
                  <img src={circleCheck} width={'39'} height={'39'} alt="check"/>
                  <p dangerouslySetInnerHTML={{__html:t('smart_check_three')}} />
                </div>
              </div>
              <BookConsultation/>
            </div>
          </section>
          <section class="cloud-based-software">
            <div class="container">
              <h2 dangerouslySetInnerHTML={{__html:t('cloud_headline')}} />
            </div>
            <div className="ui remote-manage">
              <div className="container">
                <div class="container-combo">
                  <div className="ui-text">
                    <p dangerouslySetInnerHTML={{__html:t('cloud_ui_remote')}} />
                  </div>
                  <StaticImage
                      src="../../../../images/smart-locks/digilink-remote-manage.png"
                      loading={'lazy'}
                      width={810}
                      height={424}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Remote manage users interface"
                  />
                </div>
              </div>
            </div>
            <div class="ui assign-remove">
              <div class="container">
                <div class="container-combo">
                  <div className="ui-text">
                    <p dangerouslySetInnerHTML={{__html:t('cloud_ui_assign')}} />
                  </div>
                  <StaticImage
                      src="../../../../images/smart-locks/digilink-assign-remove.png"
                      loading={'lazy'}
                      width={736}
                      height={552}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Assign or remove users interface"
                  />
                </div>
              </div>

            </div>
            <div class="ui dashboard">
              <div class="container">
                <div class="container-combo">
                  <div className="ui-text">
                    <p dangerouslySetInnerHTML={{__html:t('cloud_ui_dashboard')}} />
                  </div>
                  <StaticImage
                      src="../../../../images/smart-locks/digilink-custom-dashboard.png"
                      loading={'lazy'}
                      width={773}
                      height={398}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Custom dashboard"
                  />
                </div>
              </div>
            </div>
            <div class="ui audit">
              <div class="container">
                <div class="container-combo">
                  <div className="ui-text">
                    <p dangerouslySetInnerHTML={{__html:t('cloud_ui_audit')}} />
                  </div>
                  <StaticImage
                      src="../../../../images/smart-locks/digilink-audit.png"
                      loading={'lazy'}
                      width={757}
                      height={428}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Detailed audit"
                  />
                </div>
              </div>
            </div>
            <BookConsultation />
          </section>
          <div class="section-delimeter container"/>
          <section class="iso-certified">
            <div class="container">
              <StaticImage
                  src="../../../../images/shared/logo-sensiba.png"
                  loading={'lazy'}
                  width={102}
                  height={122}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Sensiba Logo"
              />
              <h2 dangerouslySetInnerHTML={{__html:t('digilink_soc2_copy')}} />
              <a role="button" hrefLang="en" href="/privacy-policy/" className="btn btn--blue"><Trans>learn_more</Trans></a>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
